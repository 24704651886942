<script setup>
import { defineProps, computed, defineEmits, ref } from "vue";
import { useStore } from "vuex";
const emits = defineEmits(["update:questionText", "update:questionType"]);
const props = defineProps({
  id: {
    type: [String, Number],
    required: false,
  },
  questionText: {
    type: String,
    required: true,
  },
  questionType: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: false,
  },
  allowEdit: {
    type: Boolean,
    default: true,
  },
  answer: {
    type: String,
    default: "",
  },
});
const store = useStore();
const questionTitle = computed({
  get() {
    return props.questionText;
  },
  set(value) {
    emits("update:questionText", value);
  },
});
const questionTypeModel = computed({
  get() {
    return props.questionType;
  },
  set(value) {
    emits("update:questionType", value);
  },
});
const editQuestion = ref(false);
const updateQuestion = async () => {
  const result = await store.dispatch("RePolicies/updateInterviewQuestion", {
    question_id: props.id,
    question: questionTitle.value,
    type: questionTypeModel.value,
  });
  if (result.status === 200) {
    editQuestion.value = false;
  }
};
</script>
<template>
  <div>
    <div
      class="border p-5 rounded-3xl w-full bg-lightGrey bg-opacity-50 text-darkGrey"
    >
      <div class="flex justify-between w-full">
        <div class="text-gray-500 flex items-center flex-grow">
          <input
            v-if="editQuestion"
            type="text"
            class="border border-grey rounded-md p-1 w-2/3"
            placeholder="Location"
            v-model.trim="questionTitle"
          />
          <span v-else>
            {{ answer }}
          </span>
        </div>
        <div class="text-gray-500 flex items-center flex-grow justify-end">
          <!-- <template v-if="editQuestion">
            <select
              v-model.trim="questionTypeModel"
              name="question_type"
              id="duration-type"
              class="border rounded-md p-1 col-span-2 w-2/3"
            >
              <option value="descriptive">Descriptive</option>
              <option value="single-choice">Single Choice</option>
              <option value="multi-choice">Multi Choice</option>
            </select>
          </template> -->
          <template>
            <div>
              {{ questionTypeModel }}
            </div>
          </template>
        </div>
      </div>
      <template v-if="allowEdit">
        <div
          class="flex flex-col"
          v-if="questionTypeModel && questionTypeModel != 'descriptive'"
        >
          <div class="text-gray-600 my-3">Options</div>
          <ul>
            <li
              v-for="item in props.options"
              :key="item.index"
              class="break-words mb-3"
            >
              {{ item.option }}
            </li>
          </ul>
        </div>
        <div class="flex w-full justify-end mt-5">
          <base-button
            class="flex align-center"
            @click="
              !editQuestion ? (editQuestion = !editQuestion) : updateQuestion()
            "
          >
            {{ !editQuestion ? "edit" : "update" }}
          </base-button>
        </div>
      </template>
    </div>
  </div>
</template>
