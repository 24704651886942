<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import accordion from "@/components/ui/baseComponents/accordion.vue";
import policyEditor from "./editInterviewPolicy.vue";
const store = useStore();
const route = useRoute();
const router = useRouter();
const policyData = ref(null);
const groupedQuestions = ref(null);
const groupBy = (arr, key) => {
  const initialValue = {};
  return arr?.reduce((acc, cval) => {
    const myAttribute = cval[key];
    acc[myAttribute] = [...(acc[myAttribute] || []), cval];
    return acc;
  }, initialValue);
};

onMounted(async () => {
  const { data } = await store.dispatch(
    "RePolicies/fetchSingleInterviewPolicy",
    route.params.interviewPolicyId
  );
  const groupedQuestion = groupBy(data["interview-policy"].questions, "head");
  groupedQuestions.value = groupedQuestion;
  policyData.value = data["interview-policy"];
});
const headsArray = [
  "About the Candidate",
  "Role Specific Questions",
  "Salary & Notice",
  "Questions from the Candidate",
];
const reRoute = () => {
  router.back();
};
</script>

<template>
  <div class="w-auto m-6">
    <div
      class="flex items-center text-teal mb-5 cursor-pointer w-24"
      @click="reRoute"
    >
      <span class="material-icons-outlined"> arrow_back </span>
      go Back
    </div>
    <div v-if="policyData">
      <div class="flex items-center">
        <h1 class="text-grey mr-2">{{ policyData.name }}</h1>
      </div>
    </div>
    <div class="flex flex-col gap-y-2 my-5" v-if="groupedQuestions">
      <template v-for="item in Object.keys(groupedQuestions)" :key="item.index">
        <accordion
          :questionsData="groupedQuestions[item]"
          :title="headsArray[item]"
        >
          <div class="w-auto break-all">
            <accordion
              v-for="(question, index) in groupedQuestions[item]"
              :key="index"
              :isInner="true"
              :questionsData="question"
              :title="question.question"
            >
              <policyEditor
                v-model:questionText="question.question"
                :id="question.id"
                v-model:questionType="question.type"
                :options="question.options"
              ></policyEditor>
            </accordion>
          </div>
        </accordion>
      </template>
    </div>
  </div>
</template>

<style>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.3s ease-out;
}
.fade-leave-active {
  transition: opacity 0.3s ease-in;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
